<template>
    <div class="overviewCommand">
        <div class="bread-crumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>模板详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-button icon="el-icon-back" type="text" title="返回" class="back" @click="backPage()"></el-button>
        <div label-width="100px" style="margin-top: 20px;">
            <el-form>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="模板名称">
                            <el-input style="width: 400px;" v-model="planTitle"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="模板名称">
                            <el-select v-model="planType">
                                <el-option label="日模板" value="日模板"></el-option>
                                <el-option label="周模板" value="周模板"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div v-if="this.planType == '日模板'">
            <div class="group-name">
                <div class="group"></div>
                <div class="name">日模板</div>
            </div>
            <el-table class="schedule-table" :data="tableData" border stripe>
                <el-table-column label="开始" align="center">
                    <template slot-scope="scope">
                        <el-select clearable v-model="scope.row.beginTime" :disabled="timeDisabled(scope.row.beginTime)">
                            <el-option v-for="item in planTime.time" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="结束" align="center">
                    <template slot-scope="scope">
                        <el-select clearable v-model="scope.row.endTime" :disabled="timeDisabled(scope.row.beginTime)">
                            <el-option v-for="item in planTime.time" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="类型" align="center">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.bookingDetailType" @change="changeBookingDetailType(scope.row)">
                            <el-option v-for="item of bookingDetailTypeList" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="价格" align="center" width="240">
                    <template slot-scope="scope">
                        <div style="display: flex; align-items: center">
                            <span>￥</span>
                            <el-input-number v-model="scope.row.price" :step="1" :precision="0" :max="10000"
                                style="width: 160px; margin: 0 10px"></el-input-number>
                            <span v-if="scope.row.bookingDetailType == 10">/人</span>
                            <span v-if="scope.row.bookingDetailType == 0 || // 整场
                                scope.row.bookingDetailType == 1 || // 锁场
                                scope.row.bookingDetailType == 2 // 约战
                                ">/场</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="人数" align="center">
                    <template slot-scope="scope">
                        <!-- 散客(拼场) 飞盘 -->
                        <el-button type="text" title="编辑人数"
                            v-if="scope.row.bookingDetailType == 10 || scope.row.bookingDetailType == 20"
                            @click="changePersonAmount(scope.row, scope.$index)">
                            {{ scope.row.min }} --- {{ scope.row.max }}
                        </el-button>
                        <!-- 锁场-->
                        <el-button type="text" v-if="scope.row.bookingDetailType == 1"
                            @click="fieldLock(scope.row, scope.$index)">
                            信息
                        </el-button>
                        <!-- 约战 -->
                        <el-button type="text" v-if="scope.row.bookingDetailType == 2"
                            @click="aboutWar(scope.row, scope.$index)">
                            信息
                        </el-button>
                        <!-- 整场 -->
                        <el-button type="text" v-if="scope.row.bookingDetailType == 0"> 人数不限 </el-button>
                        <!-- 闲置 -->
                        <el-button type="text" v-if="scope.row.bookingDetailType == -2"> -- </el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="changeRemark(scope.row, scope.$index)">编辑</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="状态" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" v-if="scope.row.scheduleDetailStatus == -2">-</el-button>
                        <el-button type="text" v-if="scope.row.scheduleDetailStatus == 0">待开始</el-button>
                        <!-- <el-button
                type="text"
                icon="el-icon-close"
                v-if="scope.row.scheduleDetailStatus == -1"
                title="待开始"
              >待开始</el-button> -->
                        <el-button type="text" icon="el-icon-close" v-if="scope.row.scheduleDetailStatus == -1"
                            title="已关闭">已关闭</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="120">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-delete" title="删除"
                            @click="handleDelete(scope.row, scope.$index)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="operate-btn-com">
                <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/60-xinzeng.png" title="新增"
                    style="width: 20px; height: 20px" @click="addRow" />
                <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/550.png" title="一键删除"
                    style="width: 20px; height: 20px" @click="deleteAll" />
            </div>
        </div>
        <div v-if="this.planType == '周模板'">
            <div v-for="(item, index) in weeklyData" :key="index">
                <div class="group-name">
                    <div class="group"></div>
                    <div class="name">{{ item.date }}</div>
                </div>
                <el-table class="schedule-table" :data="item.scheduleList" border stripe>
                    <el-table-column label="开始" align="center">
                        <template slot-scope="scope">
                            <el-select clearable v-model="scope.row.beginTime"
                                :disabled="timeDisabled(scope.row.beginTime)">
                                <el-option v-for="item in planTime.time" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="结束" align="center">
                        <template slot-scope="scope">
                            <el-select clearable v-model="scope.row.endTime" :disabled="timeDisabled(scope.row.beginTime)">
                                <el-option v-for="item in planTime.time" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="类型" align="center">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.bookingDetailType" @change="changeBookingDetailType(scope.row)">
                                <el-option v-for="item of bookingDetailTypeList" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" label="价格" align="center" width="240">
                        <template slot-scope="scope">
                            <div style="display: flex; align-items: center">
                                <span>￥</span>
                                <el-input-number v-model="scope.row.price" :step="1" :precision="0" :max="10000"
                                    style="width: 160px; margin: 0 10px"></el-input-number>
                                <span v-if="scope.row.bookingDetailType == 10">/人</span>
                                <span v-if="scope.row.bookingDetailType == 0 || // 整场
                                    scope.row.bookingDetailType == 1 || // 锁场
                                    scope.row.bookingDetailType == 2 // 约战
                                    ">/场</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="人数" align="center">
                        <template slot-scope="scope">
                            <!-- 散客(拼场) 飞盘 -->
                            <el-button type="text" title="编辑人数"
                                v-if="scope.row.bookingDetailType == 10 || scope.row.bookingDetailType == 20"
                                @click="changePersonAmount(scope.row, scope.$index)">
                                {{ scope.row.min }} --- {{ scope.row.max }}
                            </el-button>
                            <!-- 锁场-->
                            <!-- <el-button type="text" v-if="scope.row.bookingDetailType == 1"
                            @click="fieldLock(scope.row, scope.$index)">
                            信息
                        </el-button> -->
                            <!-- 约战 -->
                            <el-button type="text" v-if="scope.row.bookingDetailType == 2"
                                @click="aboutWar(scope.row, scope.$index)">
                                信息
                            </el-button>
                            <!-- 整场 -->
                            <el-button type="text" v-if="scope.row.bookingDetailType == 0"> 人数不限 </el-button>
                            <!-- 闲置 -->
                            <el-button type="text" v-if="scope.row.bookingDetailType == -2"> -- </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" @click="changeRemark(scope.row, scope.$index)">编辑</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" v-if="scope.row.scheduleDetailStatus == -2">-</el-button>
                            <el-button type="text" v-if="scope.row.scheduleDetailStatus == 0">待开始</el-button>
                            <el-button type="text" icon="el-icon-close" v-if="scope.row.scheduleDetailStatus == -1"
                                title="已关闭">已关闭</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" icon="el-icon-delete" title="删除"
                                @click="handleDelete(scope.row, scope.$index)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="operate-btn-com">
                    <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/60-xinzeng.png" title="新增"
                        style="width: 20px; height: 20px" @click="addWeeklyRow(index)" />
                    <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/550.png" title="一键删除"
                        style="width: 20px; height: 20px" @click="deleteAll" />
                </div>
            </div>
        </div>
        <div class="bottom-btn">
            <el-button class="template">删除</el-button>
            <el-button type="primary" class="submit-btn">保存</el-button>
        </div>


        <!-- 订单备注弹窗 -->
        <el-dialog :visible.sync="remarkVisible" width="30%" title="订单备注">
            <el-form label-width="100px">
                <el-form-item label="备注：">
                    <el-input type="textarea" :rows="5" v-model="remark" placeholder="请输入备注内容"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="handleCancelRemark">取 消</el-button>
                <el-button type="primary" @click="handleConfirmRemark">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 人数弹窗 -->
        <el-dialog :visible.sync="personVisible" width="30%" title="人数设定">
            <el-form label-width="100px">
                <el-form-item label="最小人数：">
                    <el-select clearable v-model="minAmount">
                        <el-option class="" v-for="item in planTime.min" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="最大人数：">
                    <el-select clearable v-model="maxAmount">
                        <el-option class="" v-for="item in planTime.max" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="handleCancelPerson">取 消</el-button>
                <el-button type="primary" @click="handleConfirmPerson">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 选择球队弹窗 -->
        <el-dialog :visible.sync="aboutWarVisible" width="50%" title="选择报名球队" @close="closeAboutWar">
            <el-button type="primary" @click="addTeam" style="margin-bottom: 20px">新增</el-button>
            <el-table :data="teamList" border stripe>
                <el-table-column width="35">
                    <template slot-scope="scope">
                        <el-radio :label="scope.row.teamId" v-model="radio">&nbsp;</el-radio>
                    </template>
                </el-table-column>
                <el-table-column prop="teamName" label="球队名称" align="center"> </el-table-column>
                <el-table-column prop="level" label="水平" align="center"> </el-table-column>
                <el-table-column prop="mobile" label="电话" align="center"> </el-table-column>
                <el-table-column label="操作" style="width: 100%; white-space: nowrap" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editTeam(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="conmitTeam">确认</el-button>
            </span>
        </el-dialog>
        <!-- 新增球队 -->
        <el-dialog :visible.sync="addTeamVisible" width="50%" :title="teamTitle" @close="closeTeamDialog">
            <el-form ref="form" :model="teamInfo" :rules="rules" label-width="120px" class="infoForm">
                <el-form-item label="球队Logo：">
                    <div class="uploadArea">
                        <div class="picTip">
                            <f-image-upload v-model="teamInfo.logo" @input="changeLogo" style="margin-left: 0"
                                :width="'100px'" :height="'100px'"></f-image-upload>
                        </div>
                    </div>
                </el-form-item>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="球队名称：" prop="teamName">
                            <el-input v-model="teamInfo.teamName" maxlength="10" placeholder="请输入球队名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="球队属性：" prop="teamProp">
                            <el-select v-model="teamInfo.teamProp" placeholder="请选择球队属性">
                                <el-option v-for="item in teamOption" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="电话号码：" prop="mobile">
                            <el-input v-model="teamInfo.mobile" placeholder="请输入电话号码"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="球队实力：" prop="level">
                            <el-select v-model="teamInfo.level" placeholder="请选择球队实力">
                                <el-option v-for="item in strength" :key="item.value" :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="约战次数：" prop="challengeCount">
                            <el-input v-model="teamInfo.challengeCount" placeholder="请输入约战次数"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="队员人数：" prop="playerCount">
                            <el-input v-model="teamInfo.playerCount" placeholder="请输入队员人数"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="球衣颜色：" prop="shirt">
                            <el-select v-model="teamInfo.shirt" placeholder="本队球衣颜色">
                                <el-option v-for="color of clothColors" :key="color" :label="color"
                                    :value="color"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="比赛备注：" prop="remark">
                            <el-input type="textarea" v-model="teamInfo.remark" :autosize="{ minRows: 2, maxRows: 5 }"
                                placeholder="请输入备注"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeTeamDialog">取消</el-button>
                <el-button type="primary" @click="saveTeam">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import FImageUpload from '@/common/components/Upload/f-image-upload.vue';
import planTime from '../../apidata/planTime.json';
import { deepCopy } from '@/common/utils/common/index';
export default {
    components: { FImageUpload },
    data() {
        return {
            venueList: [],
            teamList: [],
            teamInfo: {},
            rules: {
                teamName: [{ message: '请输入球队名称', required: true, trigger: 'blur' }],
                teamProp: [{ message: '请选择球队属性', required: true, trigger: 'change' }],
                mobile: [
                    {
                        pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'change',
                    },
                    { message: '请输入电话号码', required: true, trigger: 'blur' },
                ],
                level: [{ message: '请选择球队实力', required: true, trigger: 'change' }],
                shirt: [{ message: '请选择球衣颜色', required: true, trigger: 'change' }],
            },
            info: {},
            currentVenue: '',
            currentVenueInfo: {},
            planTime,
            aboutWarVisible: false,

            // 锁场
            lockVisible: false,
            lockTeamList: [
                { teamName: '', contact: '', mobile: '' },
                { teamName: '', contact: '', mobile: '' },
            ],

            addTeamVisible: false,
            teamTitle: '',
            selectDate: '',
            tableData: [],
            tableIndex: 0,
            personVisible: false,
            maxAmount: undefined,
            minAmount: undefined,
            remark: '',
            remarkVisible: false,

            // 枚举值，选项值
            bookingDetailTypeList: [
                // { value: 1, label: '锁场' },
                { value: 2, label: '约战' },
                { value: 0, label: '整场' },
                { value: 10, label: '散客' },
                // { value: 20, label: '飞盘' },
                // { value: -2, label: '闲置' },
            ],
            teamOption: [
                { value: '散客组队', label: '散客组队' },
                { value: '小区大队', label: '小区大队' },
                { value: '公司联队', label: '公司联队' },
                { value: '院校劲旅', label: '院校劲旅' },
                { value: '业余竞技', label: '业余竞技' },
                { value: '半职业队', label: '半职业队' },
            ],
            strength: [
                { value: '养生出汗', label: '养生出汗' },
                { value: '会踢能传', label: '会踢能传' },
                { value: '三俩球星', label: '三俩球星' },
                { value: '配合默契', label: '配合默契' },
                { value: '梦幻组合', label: '梦幻组合' },
                { value: '真刀真枪', label: '真刀真枪' },
            ],
            clothColors: ['混着穿', '白色', '蓝色', '红色', '黑色', '绿色', '橙色', '黄色'],
            radio: '',
            rowInfo: {},
            templateVisible: false,
            planTitle: "",
            planType: "日模板",
            weeklyData: [
                {
                    date: '周一模板',
                    scheduleList: []
                },
                {
                    date: '周二模板',
                    scheduleList: []
                },
                {
                    date: '周三模板',
                    scheduleList: []
                },
                {
                    date: '周四模板',
                    scheduleList: []
                },
                {
                    date: '周五模板',
                    scheduleList: []
                },
                {
                    date: '周六模板',
                    scheduleList: []
                },
                {
                    date: '周日模板',
                    scheduleList: []
                }
            ]
        };
    },
    mounted() {
        this.init();
        this.currentVenueInfo = this.$route.query
    },
    methods: {
        init() {
            // 获取球队
            this.getTeamList();

        },
        // 获取球场球队列表
        async getTeamList() {
            const { res } = await this.$http.get('/UserTeam/FieldTeamList');
            this.teamList = res.data || [];
        },
        // 获取场地安排计划
        async getVenueScheduleList() {
            console.log(111111,)
            this.tableData = []
            let count = (22 - this.currentVenueInfo.openingHours.split(':')[0]) / 2
            let begin = this.currentVenueInfo.openingHours.slice(0, 5)

            for (let i = 0; i < count; i++) {
                const obj = {
                    venueId: this.currentVenueInfo.venueId,
                    beginTime: begin,
                    endTime: `${parseInt(begin.split(':')[0]) + 2}:${begin.split(':')[1]}`,
                    bookType: undefined,
                    bookingDetailType: undefined,
                    price: '',
                    checkTeamId: '',
                    scheduleDetailStatus: '',
                    teamList: [],
                }
                this.tableData.push(obj)
                console.log(121234, this.tableData)
                begin = `${parseInt(begin.split(':')[0]) + 2}:${begin.split(':')[1]}`
            }
        },

        // 判断订单时间是否过期，置灰
        timeDisabled(time) {
            if (!time) {
                return false;
            }
            // 删除单个计划判断当前时间戳是否大于开始时间戳15分钟
            const currentDateTime = new Date().getTime();
            const beginDateTime = new Date(`${this.selectDate} ${time}`).getTime();
            if (currentDateTime - beginDateTime > 15 * 60 * 1000) {
                return true;
            } else {
                return false;
            }
        },
        // 修改类型
        changeBookingDetailType(row) {
            if (row.bookingDetailType == 10) {
                row.max = this.currentVenueInfo.matchType * 3;
                row.min = this.currentVenueInfo.matchType * 2;
            } else if (row.bookingDetailType == 20) {
                row.max = this.currentVenueInfo.matchType * 4;
                row.min = this.currentVenueInfo.matchType * 2;
            } else {
                row.max = undefined;
                row.min = undefined;
            }
        },
        // 修改最多、最少人数
        changePersonAmount(row, index) {
            this.tableIndex = index;
            this.maxAmount = row.max;
            this.minAmount = row.min;
            this.personVisible = true;
        },
        handleCancelPerson() {
            this.personVisible = false;
            this.maxAmount = undefined;
            this.minAmount = undefined;
        },
        handleConfirmPerson() {
            if (this.minAmount && this.maxAmount) {
                if (this.minAmount > this.maxAmount) {
                    this.$showError('最小人数不得大于最大人数');
                    return;
                }
            }
            this.personVisible = false;
            if (this.planType == '日模板') {
                this.tableData[this.tableIndex].max = this.maxAmount;
                this.tableData[this.tableIndex].min = this.minAmount;
            }
            if (this.planType == '周模板') {
                this.weeklyData.scheduleList[this.tableIndex].max = this.maxAmount;
                this.weeklyData.scheduleList[this.tableIndex].min = this.minAmount;
            }
            this.maxAmount = '';
            this.minAmount = '';
        },

        // 修改备注
        changeRemark(row, index) {
            this.tableIndex = index;
            this.remark = row.remark;
            this.remarkVisible = true;
        },
        // 取消备注按钮
        handleCancelRemark() {
            this.remark = '';
            this.remarkVisible = false;
        },
        // 确认备注按钮
        handleConfirmRemark() {
            this.remarkVisible = false;
            if (this.planType == '日模板') {
                this.tableData[this.tableIndex].remark = this.remark;
            }
            if (this.planType == '周模板') {
                this.weeklyData.scheduleList[this.tableIndex].remark = this.remark;
            }
            this.remark = '';
        },

        // 约战弹窗
        aboutWar(row, index) {
            // 当前点击行如果有数据，默认勾选radio
            if (row.teamList && row.teamList.length) {
                this.radio = row.teamList[0].teamId || '';
            }
            this.aboutWarVisible = true;
            this.tableIndex = index;
        },
        // 关闭约战弹窗
        closeAboutWar() {
            this.radio = '';
        },

        // 编辑球队
        editTeam(row) {
            this.addTeamVisible = true;
            this.teamTitle = '修改球队';
            this.teamInfo = deepCopy(row);
        },
        //新增球队弹窗
        addTeam() {
            this.addTeamVisible = true;
            this.teamTitle = '新增球队';
        },
        // 关闭球队弹窗
        closeTeamDialog() {
            this.$refs.form.clearValidate();
            this.teamInfo = {};
            this.addTeamVisible = false;
        },
        // 保存球队
        saveTeam() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.$confirm('确认保存球队信息吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(async () => {
                        const { res } = await this.$http.post('/UserTeam/SaveFieldTeam', this.teamInfo);
                        if (res.isSuccess) {
                            this.$showSuccess('保存成功');
                            this.addTeamVisible = false;
                            this.getTeamList();
                        }
                    });
                }
            });
        },
        // 球队logo
        changeLogo(v) {
            console.log(v);
            this.teamInfo.logo = v;
        },
        // 确认该球队
        conmitTeam() {
            if (!this.radio) {
                this.$showError('请选择一支球队');
                return;
            }
            const selectTeamObj = this.teamList.find((el) => {
                return el.teamId === this.radio;
            });
            const checkTeam = [];
            checkTeam.push(selectTeamObj);
            if (this.planType == '日模板') {
                this.tableData[this.tableIndex].teamList = checkTeam;
            }
            if (this.planType == '周模板') {
                this.weeklyData.scheduleList[this.tableIndex].teamList = checkTeam;
            }

            this.aboutWarVisible = false;
        },
        // 新增一条数据
        addRow() {
            const obj = {
                venueId: this.currentVenueInfo.venueId,
                beginTime: '',
                endTime: '',
                bookType: undefined,
                bookingDetailType: undefined,
                price: '',
                checkTeamId: '',
                scheduleDetailStatus: '',
                teamList: [],
            };
            this.tableData.push(obj);
        },
        // 新增周模板的一条数据
        addWeeklyRow(index) {
            console.log(1111, index)
            const obj = {
                venueId: this.currentVenueInfo.venueId,
                beginTime: '',
                endTime: '',
                bookType: undefined,
                bookingDetailType: undefined,
                price: '',
                checkTeamId: '',
                scheduleDetailStatus: '',
                teamList: [],
            };
            this.weeklyData[index].scheduleList.push(obj);
        },
        // 删除某条订单
        handleDelete(row, index) {
            // 删除单个计划判断当前时间戳是否大于开始时间戳15分钟
            const currentDateTime = new Date().getTime();
            const beginDateTime = new Date(`${this.selectDate} ${row.beginTime}`).getTime();
            if (currentDateTime - beginDateTime > 15 * 60 * 1000) {
                this.$showError('当前时间大于订单开始时间15分钟，不允许删除');
                return;
            }

            this.$confirm('确认删除该条数据吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                // 没有计划id，走物理删除
                if (!row.scheduleId) {
                    this.tableData.splice(index, 1);
                    return;
                }
            });
        },
        // 删除所有
        deleteAll() {
            // 判断当前选中日期是否小于当前日期
            const year = new Date().getFullYear();
            const month = new Date().getMonth();
            const day = new Date().getDate();
            const currentDateTime = new Date(year, month, day, 0, 0, 0);
            const historyTime = new Date(`${this.selectDate} 00:00:00`).getTime();
            if (currentDateTime > historyTime) {
                this.$showError('历史日期不允许删除所有订单');
                return;
            }
            const params = {
                venueId: this.currentVenueInfo.venueId,
                scheduleIds: [],
            };
            this.tableData.map((el) => {
                if (el.scheduleId) {
                    params.scheduleIds.push(el.scheduleId);
                }
            });
            this.$confirm('将清除本日所存在的所有订单计划(已支付订单不可用)，确认吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
        },
        // 发布订单
        handleSubmit() {
            // let lists = [];
            // lists = this.tableData.filter((el) => {
            //     if (el.bookingDetailType == 2) {
            //         return el.beginTime && el.endTime && el.price && el.teamList;
            //     } else {
            //         return el.beginTime && el.endTime && el.price;
            //     }
            // });
            this.$confirm('请核实本日订单信息，发布后即时显示在您球场主页，确定发布吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
        },
        // 返回上一页
        backPage() {
            this.$router.back(-1)
        },
    },
};
</script>
<style lang="scss" scoped>
.overviewCommand {
    // width: 100%;
    // height: 100%;
    // display: flex;
    // flex-flow: column;
    // box-sizing: border-box;

    .schedule-table {
        flex: 1;
        margin-top: 20px;

        /deep/ .el-table__body-wrapper {
            overflow-y: auto;
            height: calc(100% - 48px);
        }
    }

    .group-name {
        display: flex;
        align-items: center;

        .group {
            width: 5px;
            height: 22px;
            background-color: #134f7a;
            border-radius: 2px;
        }

        .name {
            margin-left: 10px;
            font-weight: 700;
            font-size: 20px;
        }
    }

    .operate-btn-com {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 15px;
        border-bottom: 1px solid #333;

        img {
            cursor: pointer;
        }
    }

    .bottom-btn {
        display: flex;
        justify-content: space-around;
        align-content: center;
        margin-top: 10px;
    }
}

.back {
    margin-top: 10px;
    font-size: 18px;
}
</style>