// 深拷贝
export function deepCopy(obj, cache = []) {
  function find(list, f) {
    return list.filter(f)[0];
  }

  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  const hit = find(cache, c => c.original === obj);
  if (hit) {
    return hit.copy;
  }

  const copy = Array.isArray(obj) ? [] : {};
  cache.push({
    original: obj,
    copy
  });

  Object.keys(obj).forEach(key => {
    copy[key] = deepCopy(obj[key], cache);
  });

  return copy;
}

// 球场订单表格

// export function creatTable(obj){


// }
